<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <span>Вставить товары</span>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div
        v-if="clearOptionsFiltered.length"
        class="grey lighten-4 pb-2"
      >
        <h3 class="black--text font-weight-regular mb-4 pt-4 mx-4">
          Очистить значение поля в товарах:
        </h3>
        <v-row class="mx-0">
          <v-col
            v-for="(option) in clearOptionsFiltered"
            :key="option.id"
            :cols="option.cols"
          >
            <v-checkbox
              :input-value="option.value"
              color="#5CB7B1"
              :label="option.label"
              hide-details
              @change="onOptionChange(option.id)"
            />
          </v-col>
        </v-row>
      </div>
      <div>
        <v-data-table
          :items="allowedWares"
          :headers="headers"
          hide-default-footer
          class="rounded-0"
          fixed-header
          height="500px"
        >
          <template #[`item.actions`]="{item}">
            <v-btn
              dense
              max-width="40px"
              min-width="40px"
              height="40px"
              class="elevation-0"
              @click="remove(item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="d-flex justify-end">
          <v-btn
            dark
            color="#5CB7B1"
            class="mx-5 mt-5"
            @click="submit"
          >
            Подтвердить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import { mapGetters } from 'vuex';
import {getStoreNamespaceByJournalDoc} from "@/helpers/common";
export default {
  mixins:[modalWindowMixin],
  props:{
    module: {
      validator:(v) => v === null || typeof v === 'string',
      required:true,
    },
    show:{
      type:Boolean,
      required:true,
    }
  },
  data:() => ({
    clearOptions:[
      {
        id:"clearOriginCountry",
        label:"Страна происхождения",
        allowedTypes:["sd"],
        value:false,
        cols:3
      },
      {
        id:"clearNetWeight",
        label:"Вес нетто",
        allowedTypes:["sd"],
        value:true,
        cols:2
      },
      {
        id:"clearGrossWeight",
        label:"Вес брутто",
        allowedTypes:["sd"],
        value:true,
        cols:2
      },
      {
        id:"clearInvoiceCost",
        label:"Стоимость",
        allowedTypes:["sd"],
        value:true,
        cols:2
      },
      {
        id:"clearInvoiceCurrency",
        label:"Валюта",
        allowedTypes:["sd"],
        value: false,
        cols:2
      },
      {
        id:"clearShipmentDate",
        label:"Дата отгрузки",
        allowedTypes:["sd"],
        value:true,
        cols:2
      },
    ],
    headers:[
      {
        text:"ID",
        value:"id"
      },
      {
        text:"Описание",
        value:"title"
      },
      {
        text:"",
        value:"actions",
        width:"50px"
      }
    ]
  }),
  computed:{
    ...mapGetters({
      user:"auth/getUser",
      wares:'buffer/getWares',
    }),
    allowedWares(){
      return this.wares.filter(i => i.module === this.module)
    },
    clearOptionsFiltered(){
      return this.clearOptions.filter(option => option.allowedTypes.includes(this.module))
    }
  },
  methods:{
    onOptionChange(optionId){
      const index = this.clearOptions.findIndex(option => option.id === optionId)
      const option = this.clearOptions.find(option => option.id === optionId)
      this.clearOptions.splice(index, 1, {
        ...option,
        value: !option.value
      })
    },
    remove(id){
      this.$store.commit('buffer/REMOVE_WARE', id)
    },
    getPreparedClearOptions(){
      return this.clearOptionsFiltered.reduce((previous, current) => {
        return {
          ...previous,
          [current.id]: current.value
        }
      }, {})
    },
    submit(){
      const namespace = getStoreNamespaceByJournalDoc(this.module)
      const documentId = this.$route.params.id // Партия определяется в сторе
      const ids = this.allowedWares.map(i => i.id)
      this.$store.dispatch(`${namespace}/addFromBuffer`, {
        id:documentId,
        payload: ids,
        clearOptions: this.getPreparedClearOptions()
      }).then(() => {
        this.$store.dispatch(`${namespace}/getDocumentById`, documentId)
        this.$snackbar({text:'Готово', color:'blue',top:false})
        this.close()
      }).catch(() => {
        this.$snackbar({text:'Ошибка',color:'red',top:false})
      })
    }
  }
}
</script>
